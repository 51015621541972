/**
 * Update data.
 */

const Axios = require('axios');
const ApiUrl = 'https://feed.ohmy.co/wp-json/ohmyfeed/v1';

let $button = document.getElementById('submit');
if( $button )
{
	$button.onclick = function(){
		var postId = $button.getAttribute('data-id');
		var stared = document.getElementById('stared');
		var note = document.getElementById('notes');
		var contacted = document.getElementById('contacted');

		$button.classList.toggle('is-submitting');

		updatePost(postId);
	}
}

function updatePost(id)
{

	var postData = {
		action : 'Update',
		id : id,
		stared: document.getElementById('stared').checked,
		contacted: document.getElementById('contacted').checked,
		notes: document.getElementById('notes').value,
	};

	var config = {
		headers: {
		    'Content-Type': 'application/x-www-form-urlencoded'
		}
	}

	Axios.post( ApiUrl + '/Career/Update', serialize(postData), config)
	.then(function (response) {
		$button.classList.toggle('is-submitting');
	})
	.catch(function (error) {
		alert('Nått gick fel. Kolla loggen.');
		console.log(error);
	});

}

const serialize = obj => {
   let str = [];
   for (let p in obj)
     if (obj.hasOwnProperty(p)) {
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
     }
   return str.join("&");
};


/**
 * Sort Lists
 */
const List = require('list.js');
var options = {
	valueNames: ['position', 'stared', 'title', 'notes', 'time', 'contacted']
}
var applicationsList = new List('applications', options);